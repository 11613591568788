export default {
  report: 'Отчет',
  new: 'Новый',
  sum: 'Сумма',
  time: 'Время',
  quantity: 'Количество',
  aggregated: 'Агрегированно',
  detailed: 'Подробно',
  showNAlarms: 'Показать <b>{0}</b> самые длинные уведомления',
  showOthers: 'Показать другие',
  hideOthers: 'Скрыть другие',
  sumOfSmaller: 'Сумма других',
  dropHereToCreateNew: 'Перетащите сюда, чтобы создать новую диаграмму',
  completedOrders: 'Выполненные заказы',
  history: 'История',
  usedEnergy: 'Используемая энергия',
  splitByShifts: 'Разделить по смене',
  addPersonal: 'Личная панель управления',
  addFactory: 'Заводская панель управления',
  work: 'Работа',
  plannedDowntime: 'Запланированно',
  unplannedDowntime: 'Незапланированный',
  expected: 'Ожидается',
  rootElement: 'Корневой элемент',
  performanceImpact: 'Потери производительности',
  availabilityImpact: 'Потери доступности',
  minorStops: 'Незначительные остановки',
  oeeLoss: {
    oeeLoss: 'Потеря {0}',
    productionLoss: 'Потери производства',
    undefinedIssue: 'Тип проблемы не определен',
    utilizationLosses: 'Убытки от утилизации',
    availabilityLosses: 'Потери доступности',
    performanceLosses: 'Потери производительности',
    qualityLosses: 'Потери качества',
    speedLoss: 'Потеря скорости',
    qualityLoss: 'Потеря качества',
    speedIncrease: 'Увеличение скорости',
    combined: 'Комбинированно',
    byMachine: 'Разделить по строкам / машинам',
    byProblem: 'Разделить по проблеме',
    byState: 'Разделить по штатам',
    base: 'Базовое время',
    time: {
      totalTime: 'Общее время',
      scheduledTime: 'Запланированное время',
      operatingTime: 'Рабочее время',
      productionTime: 'Сроки изготовления',
      effectiveTime: 'Эффективное время',
    },
  },
  select: {
    allLines: 'Все строки',
    allMachines: 'Все машины',
    selectMachine: 'Выбрать машину',
    selectLine: 'Выбрать линию',
    clear: 'Очистить',
  },
  alarms: {
    description: 'Описание',
    numberOfOccurances: 'Число',
    duration: 'Продолжительность',
    sumOfSmallerAlarms: 'Сумма меньших уведомлений',
    noDataInThisPeriod: 'Нет данных за этот период',
  },
  selectors: {
    period: 'Период',
    moment: 'Момент',
    dateTo: 'Дата до',
    dateFrom: 'Дата, с',
    shift: 'Смена',
    day: 'День',
    week: 'Неделя',
    month: 'Месяц',
    today: 'Сегодня',
    yesterday: 'Вчера',
    thisWeek: 'На этой неделе',
    previousWeek: 'Прошлая неделя',
    thisMonth: 'Этот месяц',
    previousMonth: 'Предыдущий месяц',
    selectLineAndMachine: 'Выберите линию и машину',
    selectLine: 'Выберите линию',
    selectLineOrMachine: 'Выберите линию или машину',
    timeFilter: 'Фильтр времени',
    lastThreeMonths: 'Последние 3 месяца',
    selectExactDates: 'Выберите точные даты',
    selectDatesFromList: 'Выберите даты из списка',
    addElement: 'Добавить фабричный элемент в отчет',
  },
  types: {
    custom: 'Обычай',
    availability: 'Доступность',
    efficiency: 'Эффективность',
    oee: 'OEE',
    oeeLosses: 'Потери OEE',
    production: 'Производство',
    productionMap: 'Карта производства',
    downtimes: 'Время простоя',
    rootcauses: 'Основные причины простоя',
    alarms: 'Уведомления',
    column: 'Столбец',
    pie: 'Пирог',
    operatorStops: 'Оператор останавливается',
    downtimeDuration: 'Продолжительность простоя',
    downtimeDurations: 'Продолжительность простоя',
    reasons: 'Причины',
    correctiveActions: 'Корректирующие действия',
    energy: 'Энергия',
    energySankey: 'Санки',
    energyHistory: 'История использования',
    heatmap: 'Тепловая карта',
    commentsFromLosses: 'Комментарии по потерям',
    machines: 'Машины',
  },
  operatorStop: {
    waiting: 'В ожидании действия',
    fixing: 'Продолжительность действия',
    stopsBecauseOf: 'Остановка из-за',
    timeSum: 'Общая продолжительность',
  },
  rootcause: {
    impact: {
      title: 'Влияние',
      desc: '(Продолжит. эффекта / Продолжит. основной причины)'
    },
    effectDuration: 'Продолжительность эффекта',
    rootcauseDuration: 'Продолжительность корневых причин'
  },
  chart: { toggleFullView: 'Остановить полноэкранный режим' },
  machines: {
    line: 'Линия',
    automatic: 'Автоматы',
    manual: 'Ручные машины',
    dummy: 'Машины-стенды',
    sum: 'Сумма',
    aggregate: 'Совокупно',
  },
};
